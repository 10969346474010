&.show_detail {
	header.header.sticky {
		@include media-breakpoint-down(lg) {
			position: relative !important;
		}
	}

	.page_block.show_detail_intro {
		@include make-row();
		@extend .pb-5;

		.info {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}
		}

		.info-extra {
			@include make-col-ready();
			@include make-col(12);

			@include media-breakpoint-up(lg) {
				@include make-col(6);
			}
		}
	}

	.cast-section {
        text-align: center;
        margin-bottom: 3rem;

        .contentblock {
            margin-top: 3rem;

			&.owl-carousel {
				min-height: 0;
				padding: 0;

				.item {
					min-height: 0;
					padding: 0;

					.card {
						background: none;
						border: none;
						border-radius: 0;
						text-decoration: none;

						.card-image {
							margin-bottom: 15px;
							background: none;
							border-radius: 100%;
							aspect-ratio: 1;
							overflow: hidden;

							img {
								height: 100%;
								object-fit: cover;
								transition: 0.5s;
							}

							&:hover {
								img {
									transform: scale(1.05);
								}
							}
						}

						.card-body {
							padding: 0;
							background: none;

							.card-caption {

								.card-title,
								.card-subtitle {
									margin-bottom: 0.25rem;
									color: $black;
									font-size: 17px;
									text-align: center;
								}

								.card-title {
									font-weight: 700;
								}

								.card-subtitle {
									font-weight: 400;
								}
							}

							.card-buttons {
								display: none;
							}
						}
					}
				}

				.owl-dots {
					.owl-dot {
						span {
							border-color: $primary;
						}

						&:hover {
							span {
								background: $primary;
							}
						}

						&.active {
							span {
								background: $primary;
								border-color: $primary;
							}
						}
					}
				}
			}
		}
    }

	// fancybox
	.fancybox-card-title {
		font-size: 24px;
		font-weight: 600;
	}

	.fancybox-card-subtitle {
		font-size: 18px;
		font-weight: 500;
	}

	.fancybox-card-description {
		font-size: 16px;
	}
}
